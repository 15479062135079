// import { cssAnimateNewHeight } from '../utils/helpers';

export function initFaq() {


    function slidetoggle() {

        document.querySelectorAll(this.getAttribute('data-slidetoggle')).forEach(el => {


            const ch = el.clientHeight,
                sh = el.scrollHeight,
                isCollapsed = !ch,
                noHeightSet = !el.style.height;

            el.style.height = `${isCollapsed || noHeightSet ? sh : 0}px`;
            // if (noHeightSet) return slidetoggle.call(this);
            // console.log("call");

            this.classList.toggle('faq-open');
        });

    }

    document.querySelectorAll("[data-slidetoggle]").forEach(el => el.addEventListener('click', slidetoggle));
}
