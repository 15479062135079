import {disableScrollLock, enableScrollLock} from "../utils/scrollLock";

export function init() {
    //mandrill forms
    const navigationTakeoverToggles = document.querySelectorAll('[data-action*="navigation-takeover-toggle"]');

    function navigationToggle (e) {
        e.preventDefault();

        if (!document.body.classList.contains('nav-open')) {
            enableScrollLock();
            document.body.classList.add('nav-open');
        } else {
            setTimeout(disableScrollLock, 400);
            document.body.classList.remove('nav-open');
        }
    }

    for (let i = 0; i < navigationTakeoverToggles.length; i++) {
        navigationTakeoverToggles[i].addEventListener('click', navigationToggle);
    }
}
