import * as variables from '../utils/variables';
import Rellax from "rellax";
import { onWindowResize, currentWindowWidth } from '../utils/windowResize';

export function initRellaxJs () {
    let rellaxSelector = '.rellax';
    if (variables.header && variables.header.offsetHeight > variables.windowHeight) {
        rellaxSelector = '.rellax:not(.header)';
    }

    var rellax = new Rellax(rellaxSelector);
    onWindowResize((() => {
        if (currentWindowWidth > 480 && currentWindowWidth < 768) {
            rellax.destroy();
        } else {
            rellax.refresh();
        }
    }));
}

