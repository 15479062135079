import { activityThrottler } from "../utils/trottle";

//use window.scrollY
let scrollpos = window.scrollY;
const header = document.getElementById("header");

// eslint-disable-next-line camelcase
function addClassOnScroll() {
    header.classList.add("scroll");
}

// eslint-disable-next-line camelcase
function removeClassOnScroll() {
    header.classList.remove("scroll");
}


function scrollEvent() {
    //Here you forgot to update the value
    scrollpos = window.scrollY;

    if (scrollpos > 10) {
        addClassOnScroll();
    }
    else {
        removeClassOnScroll();
    }
}

export function initScrollHeader() {
    window.addEventListener('scroll', () => {
        scrollEvent();
    });

}
