export const breakpoints = {
    "xxs": 1,
    "xs": 480,
    "sm": 768,
    "md": 1024,
    "lg": 1280,
    "xl": 1540,
    "xxl": 1840
};

export const breakpointKeys = Object.keys(breakpoints);
export let currentWindowWidth = window.innerWidth;
export let currentWindowHeight = window.innerHeight;
export let currentBreakpoint = 0;
let resizeTimer;

const resizeFunctions = [];

/**
 * Get various window sizes - width, height etc.
 * This function is fired automatically upon page load. and run each time the window changes size.
 *
 */
function getWindowSizes() {

    currentWindowWidth = window.innerWidth;
    currentWindowHeight = window.innerHeight;

    // Calculate which breakpoint is currently active, based on the screen width compared to the breakpoint definitions.

    let lastFoundWidth = 0;

    breakpointKeys.forEach((key, index) => {
        const width = breakpoints[key];
        if (currentWindowWidth >= width && width > lastFoundWidth) {
            lastFoundWidth = width;
            currentBreakpoint = index;
        }
    });
}

function resizeHandler() {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(() => {
        getWindowSizes();
        resizeFunctions.forEach(funcRef => funcRef());
    }, 100);
}

export function onWindowResize(handler) {
    resizeFunctions.push(handler);
}

export function initWindowResize() {
    getWindowSizes();
    window.addEventListener('resize', resizeHandler);
    window.addEventListener('orientationchange', resizeHandler);
}
